import api from ".";

//this will contain all the api requests related to authentication

export const register = async (data = {}) => {
  try {
    const response = await api.post(`/register/user`, data);

    return { user: response.data };
  } catch (err) {
    return { error: err.message };
  }
};
export const registerwithPhone = async (data = {}) => {
  try {
    const response = await api.post(`/register/user/phone`, data);

    return { user: response.data };
  } catch (err) {
    return { error: err.message };
  }
};
export const forgotwithPhone = async (data = {}) => {
  try {
    const response = await api.post(`/forgot/user/phone`, data);

    return { user: response.data };
  } catch (err) {
    return { error: err.message };
  }
};

export const login = async (data = {}) => {
  try {
    const response = await api.post(`/login/user`, data);

    return { user: response.data };
  } catch (err) {
    return { error: err.message };
  }
};
export const loginwithPhone = async (data = {}) => {
  try {
    const response = await api.post(`/login/user/phone`, data);

    return { user: response.data };
  } catch (err) {
    return { error: err.message };
  }
};

export const googleAuth = async (data = {}) => {
  try {
    const response = await api.post("/auth/user/google", data);

    return { user: response.data };
  } catch (err) {
    return { error: err.message };
  }
};

//incomplete logout route yet to be completed
export const logout = async () => {
  try {
    window.localStorage.removeItem("auth");
    return { user: true };
  } catch (err) {
    return { error: err.message };
  }
};

export const forgott = async (data) => {
  try {
    const response = await api.post(`/forgotpassword`, data);

    console.log("🚀 ~ file: authRequests.js:52 ~ forgott ~ response", response);
    return response;
  } catch (err) {
    return { error: err.message };
  }
};

export const restPassword = async (data) => {
  try {
    const response = await api.post(`/resetpassword`, data);
    return response;
  } catch (err) {
    return { error: err.message };
  }
};
