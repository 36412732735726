import { Navigate } from "react-router-dom";
import { useGlobalContext } from "../../utils/contexts";
import Login from "../old_Home/old_Login/Login";
import { useState } from "react";
function RequiredAuth({ children }) {
  // Get auth from global context
  const { auth } = useGlobalContext();
  const [active, setActive] = useState(false); // Track login modal visibility

  // Check authentication and show login modal if not authenticated
  if (!auth?._id) {
    if (!active) setActive(true); // Open the login modal
    return (
      <>
        {active && (
          <Login
            setActive={setActive} // Pass setActive to close the modal on login success
          />
        )}
      </>
    );
  }

  // If authenticated, render children
  return children;
}

export default RequiredAuth;
