/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./LoadPage.css"; // Import the CSS file for styles
import funFacts from "./funfacts.json";
import logo1 from "../../assets/new_logo.svg";
import logo2 from "../../assets/new_logo.svg";
import logo4 from "../../assets/new_logo.svg";

const LoadPage = () => {
  const [randomFact, setRandomFact] = useState("");

  const logos = [logo1, logo2, logo4];
  const [currentLogoIndex, setCurrentLogoIndex] = useState(0);

  useEffect(() => {
    // Function to get a random fun fact
    const getRandomFact = () => {
      const randomIndex = Math.floor(Math.random() * funFacts.length);
      return funFacts[randomIndex];
    };

    // Set a random fun fact when the component mounts
    setRandomFact(getRandomFact());
  }, []);

  useEffect(() => {
    const logoAnimationTimeout = setTimeout(() => {
      setCurrentLogoIndex((prevIndex) => (prevIndex + 1) % logos.length);
    }, 300);

    return () => clearTimeout(logoAnimationTimeout);
  }, [currentLogoIndex]);

  return (
    <>
      <section className="flex flex-col items-center h-screen bg-white text-black">
        <div className="flex mt-48 ">
          {/* <img
            src={logos[currentLogoIndex]}
            alt={`Logo ${currentLogoIndex + 1}`}
            className="logo h-24"
          /> */}
          <img
            src={logos[0]}
            alt={`Logo ${currentLogoIndex + 1}`}
            className="logo h-24"
          />
        </div>

        <div>
          <p className="text-center mt-4 font-semimedium mx-4 md:mx-32 lg:mx-72 font-poppins">
            {randomFact}
          </p>
        </div>
      </section>
    </>
  );
};

export default LoadPage;
