import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
// import style from "./Login.module.scss";
import { FcGoogle } from "react-icons/fc";
import axios from "axios";
import { googleAuthHandler } from "../../../utils/authHandlers";

function GoogleLogin(props) {
  // const { registerForm, setActive, setAuth, buttonText } = props;
  const { registerForm, buttonText } = props;
  const googleLogin = useGoogleLogin({
    scope: "https://www.googleapis.com/auth/userinfo.profile",
    onSuccess: (tokenResponse) => {
      getUserInfo(tokenResponse);
    },
  });

  const getUserInfo = async (tokenResponse) => {
    try {
      const response = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        }
      );
      const userinfo = response.data;
      const data = {
        name: userinfo.name,
        email: userinfo.email,
        password: userinfo.sub,
        confirm_password: userinfo.sub,
      };
      await googleAuthHandler(data, props.setAuth, props.setActive);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      {registerForm ? (
        <div className="flex items-center gap-2" onClick={() => googleLogin()}>
          <FcGoogle /> {buttonText}
        </div>
      ) : (
        <div className="flex items-center gap-2" onClick={() => googleLogin()}>
          <FcGoogle /> {buttonText}
        </div>
      )}
    </>
  );
}

export default GoogleLogin;
