import { Navigate } from "react-router-dom";
import { useGlobalContext } from "../../utils/contexts";

function RequiredAdmin({ children }) {
  // get user
  const { auth } = useGlobalContext();

  if (!auth?.admin) {
    return <Navigate to="/" />;
  } else {
    return children;
  }
}

export default RequiredAdmin;
