import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const RedirectToInstagram = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.location.href = "https://www.instagram.com/ar/3853124554969483"; // Redirect to Instagram
  }, []);

  return null;
};
