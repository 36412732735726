import { createContext, useContext } from "react";

const globalContext = createContext({});

function GlobalContextProvider({ context, children }) {
  return (
    <globalContext.Provider value={context}>{children}</globalContext.Provider>
  );
}

export default GlobalContextProvider;

//global context to be used throughout the website you can just import this function instead of importing context n then using hook
export const useGlobalContext = () => {
  return useContext(globalContext);
};

export const GAMES_OPTIONS = [
  {
    name: "BGMI",
    regions: ["IN"],
  },
  {
    name: "Valorant",
    regions: ["EU", "KR", "LATAM", "NA"],
  },
  {
    name: "Chess",
    regions: ["All regions"],
  },
  {
    name: "COD Warzone",
    regions: ["AS", "EU", "ME", "NA", "RU", "SA"],
  },
  {
    name: "Apex Legends",
    regions: ["AU", "BE", "BR", "DE", "HK", "JP", "NL"],
  },
  {
    name: "Fortnite",
    regions: ["AS", "BR", "EU", "ME", "NAE", "NAW", "OCE"],
  },
  {
    name: "Freefire",
    regions: ["IN"],
  },
  {
    name: "Rocket League",
    regions: ["AU", "BE", "BR", "DE", "FR", "IT", "JP", "NL", "UK", "US"],
  },
  {
    name: "CSGO",
    regions: ["BR", "EU", "JP", "KR", "NA", "OCE"],
  },
  {
    name: "League of Legends",
    regions: [
      "BR",
      "CN",
      "EUNE",
      "EUW",
      "JP",
      "KR",
      "LAN",
      "LAS",
      "NA",
      "OCE",
      "RU",
      "SEA",
      "TR",
    ],
  },
  {
    name: "Dota 2",
    regions: [
      "BR",
      "CN",
      "EUNE",
      "EUW",
      "JP",
      "KR",
      "LAN",
      "LAS",
      "NA",
      "OCE",
      "RU",
      "SEA",
      "TR",
    ],
  },
];
