import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  active: false,
  fen: "",
  type: null,
  currentRushPuzzle: 0,
  wrongMove: false,
  puzzleCompleted: false,
  rushPuzzles: [],
  autoTurn: false,
  playerTurn: false,
  puzzle: {
    startingFen: "",
    moves: [],
    numberOfMoves: 0,
    playerColor: "w",
  },
  dialog: {
    wrongMove: {
      open: false,
    },
  },
};

const chessPuzzle = createSlice({
  name: "chessPuzzle",
  initialState,
  reducers: {
    reset: () => initialState,
    exitPuzzle(state) {
      state.currentRushPuzzle = 0;
      state.autoTurn = false;
      state.playerTurn = false;
      state.wrongMove = false;
      state.puzzle.numberOfMoves = 0;
      state.puzzleCompleted = false;
      state.rushPuzzles = [];
      state.active = false;
    },
    resetPuzzleMode(state) {
      state.active = false;
      state.wrongMove = false;
    },
    restartPuzzle(state) {
      state.autoTurn = false;
      state.playerTurn = false;
      state.wrongMove = false;
      state.puzzle.numberOfMoves = 0;
      state.puzzleCompleted = false;
    },
    set(state, action) {
      state.active = true;
      state.fen = action.payload?.puzzle?.startingFen;
      state.puzzle = action.payload.puzzle;
      state.type = action.payload?.type;
    },
    setCurrentPuzzleNo(state, action) {
      state.currentRushPuzzle = action.payload;
    },
    setPuzzle(state, action) {
      state.active = true;
      state.puzzle = action.payload;
    },
    playersTurn(state) {
      state.active = true;
      state.playerTurn = true;
    },
    blockPlayer(state) {
      state.playerTurn = false;
    },
    moveIncrement(state) {
      state.puzzle.numberOfMoves += 1;
    },
    setRushPuzzles(state, action) {
      // console.log("setRushPuzzles called with payload:", action.payload); // Add this log
      state.type = "rush";
      state.active = true;
      state.rushPuzzles = action.payload;
    },
    toggleAutoTurn(state) {
      state.autoTurn = !state.autoTurn;
    },
    setFen(state, action) {
      state.active = true;
      state.fen = action.payload;
    },
    setWrongMove(state, action) {
      state.wrongMove = action.payload;
      state.playerTurn = false;
    },
    setOnNextPuzzle(state) {
      state.autoTurn = false;
      state.playerTurn = false;
      state.wrongMove = false;
      state.puzzle.numberOfMoves = 0;
      state.puzzleCompleted = false;
    },
    setPuzzleCompleted(state) {
      state.puzzleCompleted = true;
    },
    rushPuzzleUpdate(state, action) {
      state.rushPuzzles[action.payload.index] = {
        ...state.rushPuzzles[action.payload.index],
        puzzleState: action.payload.update,
      };
    },
  },
});

export const {
  set,
  reset,
  resetPuzzleMode,
  setPuzzle,
  setRushPuzzles,
  playersTurn,
  blockPlayer,
  moveIncrement,
  setFen,
  toggleAutoTurn,
  setWrongMove,
  restartPuzzle,
  exitPuzzle,
  setCurrentPuzzleNo,
  setPuzzleCompleted,
  rushPuzzleUpdate,
} = chessPuzzle.actions;

export default chessPuzzle.reducer;
