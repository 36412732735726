import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    historyId: null,
    dailyPuzzleHistory:{},
}

const historySlice = createSlice({
    name:"history",
    initialState,
    reducers:{
        reset:()=> initialState,
        setMatchHistoryId(state, action){
            state.historyId = action.payload;
        },
        setDailyPuzzleHistory(state, action){
            state.dailyPuzzleHistory = action.payload;
        }
    }
});

export const { reset, setMatchHistoryId, setDailyPuzzleHistory } =  historySlice.actions;

export default historySlice.reducer;