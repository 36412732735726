/** @format */

import {
  googleAuth,
  login,
  logout,
  register,
  forgott,
  restPassword,
  registerwithPhone,
  loginwithPhone,
  forgotwithPhone,
} from "../api/authRequests";
import { isNonEmpty } from "./utils";

import {
  successRegistrationToast,
  warningRegistrationToast,
  warningPasswordShouldMatchToast,
  warningEnterAllDetailsToast,
  failedLoginToast,
  successLoginToast,
  failedLogoutToast,
  successLogoutToast,
  successForgotToast,
  failedForgotToast,
  successRestToast,
  failedRestToast,
} from "./notifications";
import CryptoJS from "crypto-js";

//register the user
export const registrationHandler = async (data = {}, setAuth, setActive) => {
  const { email, name, password, confirm_password } = data;

  if (!isNonEmpty([email, name, password, confirm_password])) {
    warningEnterAllDetailsToast();
  } else if (confirm_password !== password) {
    warningPasswordShouldMatchToast();
  } else {
    delete data.confirm_password;
    const hashedPassword = CryptoJS.AES.encrypt(
      password,
      `${process.env.REACT_APP_FRONTEND_PASS_HASHED_SECRET}`
    ).toString();
    const result = await register({ email, name, password: hashedPassword });

    if (result.user) {
      setAuth(result.user);
      window.localStorage.setItem("auth", JSON.stringify(result.user));
      successRegistrationToast();
    } else {
      warningRegistrationToast();
    }
    setActive(false);
  }
};
export const registrationHandlerwithPhone = async (
  data = {},
  setAuth,
  setActive
) => {
  const { phoneNumber, name, password, confirm_password } = data;

  if (!isNonEmpty([phoneNumber, name, password, confirm_password])) {
    warningEnterAllDetailsToast();
  } else if (confirm_password !== password) {
    warningPasswordShouldMatchToast();
  } else {
    delete data.confirm_password;
    const hashedPassword = CryptoJS.AES.encrypt(
      password,
      `${process.env.REACT_APP_FRONTEND_PASS_HASHED_SECRET}`
    ).toString();
    const result = await registerwithPhone({
      phoneNumber,
      name,
      password: hashedPassword,
    });

    if (result.user) {
      setAuth(result.user);
      window.localStorage.setItem("auth", JSON.stringify(result.user));
      successRegistrationToast();
    } else {
      warningRegistrationToast();
    }
    setActive(false);
  }
};
export const forgotHandlerwithPhone = async (data = {}, setAuth, setActive) => {
  const { phoneNumber, password, confirm_password } = data;

  if (!isNonEmpty([phoneNumber, password, confirm_password])) {
    warningEnterAllDetailsToast();
  } else if (confirm_password !== password) {
    warningPasswordShouldMatchToast();
  } else {
    delete data.confirm_password;
    const hashedPassword = CryptoJS.AES.encrypt(
      password,
      `${process.env.REACT_APP_FRONTEND_PASS_HASHED_SECRET}`
    ).toString();
    const result = await forgotwithPhone({
      phoneNumber,
      password: hashedPassword,
    });

    if (result.user) {
      setAuth(result.user);
      window.localStorage.setItem("auth", JSON.stringify(result.user));
      successRestToast();
    } else {
      failedRestToast();
    }
    setActive(false);
  }
};

// register, login using google
export const googleAuthHandler = async (data = {}, setAuth, setActive) => {
  const { email, name, password, confirm_password } = data;

  if (!isNonEmpty([email, name, password, confirm_password])) {
    warningEnterAllDetailsToast();
  } else if (confirm_password !== password) {
    warningPasswordShouldMatchToast();
  } else {
    delete data.confirm_password;

    const result = await googleAuth(data);

    if (result.user) {
      successLoginToast();
      setAuth(result.user);
      window.localStorage.setItem("auth", JSON.stringify(result.user));
    } else {
      warningRegistrationToast();
    }
    setActive(false);
  }
};

//login the user
export const loginHandlerwithPhone = async (data = {}, setAuth, setActive) => {
  const { phoneNumber, password } = data;
  if (!isNonEmpty([phoneNumber, password])) {
    warningEnterAllDetailsToast();
  } else {
    const result = await loginwithPhone(data);

    if (result.user) {
      successLoginToast();
      setAuth(result.user);
      window.localStorage.setItem("auth", JSON.stringify(result.user));
    } else {
      failedLoginToast();
    }
    setActive(false);
  }
};
export const loginHandler = async (data = {}, setAuth, setActive) => {
  const { email, password } = data;
  if (!isNonEmpty([email, password])) {
    warningEnterAllDetailsToast();
  } else {
    const result = await login(data);

    if (result.user) {
      successLoginToast();
      setAuth(result.user);
      window.localStorage.setItem("auth", JSON.stringify(result.user));
    } else {
      failedLoginToast();
    }
    setActive(false);
  }
};

//logout user
export const logoutHandler = async ({ navigate, setAuth }) => {
  const result = await logout();
  if (result.user) {
    successLogoutToast();
    window.localStorage.removeItem("auth");
    setAuth(null);
    navigate("/");
  } else {
    failedLogoutToast();
  }
};

//password
export const passwordHandler = async (email) => {
  const data = { email: email };
  if (email) {
    const result = await forgott(data);
    console.log(
      "🚀 ~ file: authHandlers.js:111 ~ passwordHandler ~ result",
      result
    );

    if (result.error) {
      failedForgotToast();
    } else {
      successForgotToast();
    }
  } else {
    warningEnterAllDetailsToast();
  }
};

//rest password
export const restPasswordHandler = async (id, pwd) => {
  const data = {
    id: id,
    password: pwd,
  };
  if (id && pwd) {
    const result = await restPassword(data);

    if (result.error) {
      failedRestToast();
    } else {
      successRestToast();
    }
  } else {
    failedRestToast();
  }
};
