import axios from "axios";
import { getAuthUser } from "./utils";
import { registerationSuccessfull } from "./notifications";

const auth = getAuthUser();

export const paymentOptions = (type = "razorpay") => {
  const phonepePayment = (body) =>
    /**
         * for bootcamp
         * body : {
                orderId:params?.id,
                type:"bootcamp",
                userIntro,
          }
         * 
         * 
         */
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/payment/phonepe/init`,
        body,
        {
          headers: {
            authorization: `Bearer ${auth?.accessToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data?.success) {
          // console.log(response.data);
          window.open(response.data?.redirect, "_self");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  const cashfreePayment = (body) =>
    /**
         * body: {
         *      orderId
         *      type
                userid,
                useremail,
                username,
                userIntro,
                toPayPrice,
            }
         */
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/payment/create-order?order=${
          body?.orderId
        }&type=${body.type}&plan=${body.plan || ""}`,
        body,
        {
          headers: {
            authorization: `Bearer ${auth?.accessToken}`,
          },
        }
      )
      .then((response) => {
        if (response) {
          // console.log(response.data);
          window.open(response.data.paymentLink, "_self");
        }
      })
      .catch(function (error) {
        console.log(error);
      });

  const handlePayment = async (status, values = {}, navigate = () => {}) => {
    let apiLink = `${process.env.REACT_APP_BACKEND_URL}/api/payment/rz/callback`;
    if (values.type === "checkout") {
      apiLink = `${process.env.REACT_APP_BACKEND_URL}/api/payment/rz/checkout-callback`;
    }
    if (values.type === "batchPayment") {
      apiLink = `${process.env.REACT_APP_BACKEND_URL}/api/payment/rz/callBackbatchOrder`;
    }
    if (values.type === "tournament") {
      apiLink = `${process.env.REACT_APP_BACKEND_URL}/api/payment/rz/callBacktournamentOrder`;
    }
    if (values.type === "schoolOrder") {
      apiLink = `${process.env.REACT_APP_BACKEND_URL}/api/payment/rz/callBackschoolorder`;
    }
    if (values.type === "weekendClass") {
      apiLink = `${process.env.REACT_APP_BACKEND_URL}/api/payment/rz/callWeekClass`;
    }
    if (values.type === "chessBootCamp") {
      apiLink = `${process.env.REACT_APP_BACKEND_URL}/api/payment/rz/callChessBootCamp`;
    }
    if (values.type === "chessBootCampPayment") {
      apiLink = `${process.env.REACT_APP_BACKEND_URL}/api/payment/rz/callChessBootCampPayment`;
    }
    if (values.type === "allschoolOrder") {
      apiLink = `${process.env.REACT_APP_BACKEND_URL}/api/payment/rz/callAllSchoolPayment`;
    }
    if (values.type === "schoolOrder590") {
      apiLink = `${process.env.REACT_APP_BACKEND_URL}/api/payment/callBackSchoolPayment590`;
    }
    await axios
      .post(apiLink, {
        status,
        ...values,
      })
      .then((res) => {
        // console.log(res.data);

        if (res.data?.id || res.data?.transactionid) {
          if (res.data?.type === "1v1") {
            navigate(`/orderfinal/${res.data?.id}/true`);
          }
          // window.location.replace(res.data?.redirect);
          else if (res.data?.type === "bootcamp") {
            // console.log("heh hehhheeee");
            // window.location.replace(res.data?.redirect)
            navigate(`/orderfinal/${res.data?.id}/true`);
          } else if (
            res.data?.type === "checkout" ||
            res.data?.type === "schoolOrder" ||
            res.data?.type === "weekendClass" ||
            res.data?.type === "chessBootCamp" ||
            res.data?.type === "chessBootCampPayment" ||
            res.data?.type === "allschoolOrder" ||
            res.data?.type === "schoolOrder590"
          ) {
            //  console.log("res data",res.data?.type);
            sessionStorage.setItem("payment_orderId", res.data.transactionid);
            window.open(res.data?.redirect, "_self");
          } else if (res.data?.type === "batchPayment") {
            window.open(res.data?.redirect, "_self");
          } else if (res.data?.type === "tournament") {
            registerationSuccessfull();
            window.open(res.data?.redirect, "_self");
          }

          // window.location.reload();
        }
      })
      .catch((e) => console.log(e));
  };
  const openRazorpayModal = function (body = {}, navigate) {
    var apiLink = `${process.env.REACT_APP_BACKEND_URL}/api/payment/rz/create-order`;
    if (body.type === "checkout") {
      apiLink = `${process.env.REACT_APP_BACKEND_URL}/api/payment/rz/checkout-order`;
    }
    if (body.type === "schoolOrder") {
      apiLink = `${process.env.REACT_APP_BACKEND_URL}/api/payment/rz/school-order`;
    }
    if (body.type === "batchPayment") {
      apiLink = `${process.env.REACT_APP_BACKEND_URL}/api/payment/rz/batchOrder`;
    }
    if (body.type === "tournament") {
      apiLink = `${process.env.REACT_APP_BACKEND_URL}/api/payment/rz/tournamentPayment`;
    }
    if (body.type === "weekendClass") {
      apiLink = `${process.env.REACT_APP_BACKEND_URL}/api/payment/rz/week-class`;
    }
    if (body.type === "chessBootCamp") {
      apiLink = `${process.env.REACT_APP_BACKEND_URL}/api/payment/rz/chessBootCamp`;
    }
    if (body.type === "chessBootCampPayment") {
      apiLink = `${process.env.REACT_APP_BACKEND_URL}/api/payment/rz/chessBootCampPayment`;
    }
    if (body.type === "allschoolOrder") {
      apiLink = `${process.env.REACT_APP_BACKEND_URL}/api/payment/rz/allSchoolPayment`;
    }
    if (body.type === "schoolOrder590") {
      apiLink = `${process.env.REACT_APP_BACKEND_URL}/api/payment/createSchoolPayment590`;
    }
    var options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount: 0, // 2000 paise = INR 20, amount in paisa
      name: "PlayerOrbit Sports Pvt. Ltd.",
      description: "",
      image:
        "https://www.playerorbit.com/static/media/new_logo.099a80084fa37368c6447f1b3a017173.svg",
      order_id: "",
      handler: function (response) {
        console.log(response);
        var values = {
          razorpay_signature: response.razorpay_signature,
          razorpay_order_id: response.razorpay_order_id,
          transactionid: response.razorpay_payment_id,
          ...body,
        };
        handlePayment("completed", values, navigate);
      },
      prefill: {
        name: auth?.name,
        // email: auth?.email,
        contact: auth?.phoneNumber ? auth.phoneNumber : "",
      },
      modal: {
        confirm_close: true, // this is set to true, if we want confirmation when clicked on cross button.
        // This function is executed when checkout modal is closed
        // There can be 3 reasons when this modal is closed.
        ondismiss: async (reason) => {
          const {
            reason: paymentReason,
            field,
            step,
            code,
          } = reason && reason.error ? reason.error : {};
          // Reason 1 - when payment is cancelled. It can happend when we click cross icon or cancel any payment explicitly.
          if (reason === undefined) {
            console.log("cancelled");
            handlePayment("Cancelled");
          }
          // Reason 2 - When modal is auto closed because of time out
          else if (reason === "timeout") {
            console.log("timedout");
            handlePayment("timedout");
          }
          // Reason 3 - When payment gets failed.
          else {
            console.log("failed");
            handlePayment("failed", {
              error: { paymentReason, field, step, code },
            });
          }
        },
      },
      notes: {
        orderId: body?.orderId,
        type: body?.type,
        userEmail: body?.userEmail,
      },
      theme: {
        color: "#ADDCFF",
      },
    };
    axios
      .post(
        apiLink,
        {
          orderId: body?.orderId,
          type: body?.type,
          userEmail: body?.useremail,
          plan: body?.plan,
          ...body,
        },
        {
          headers: {
            authorization: `Bearer ${auth?.accessToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        options.order_id = res.data.id;
        options.amount = res.data.amount;
        console.log(options);

        var rzp1 = new window.Razorpay(options);
        rzp1.open();
      })
      .catch((e) => console.log(e));
  };
  // const paymentOptions = [];
  if (type === "cashfree") {
    return cashfreePayment;
    // paymentOptions.push(cashfreePayment);
  }
  if (type === "razorpay") {
    return openRazorpayModal;
    // paymentOptions.push();
  }
  if (type === "phonepe") {
    return phonepePayment;
    // paymentOptions.push(phonepePayment);
  }
  // return paymentOptions;
};
