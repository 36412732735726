/** @format */

// import { AiFillMail, AiOutlineClose } from "react-icons/ai";
import React, { useState, useEffect } from "react";
import {
  loginHandler,
  registrationHandler,
  // passwordHandler,
  registrationHandlerwithPhone,
  loginHandlerwithPhone,
  forgotHandlerwithPhone,
} from "../../../utils/authHandlers";

import GoogleLogin from "./GoogleLogin";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import logo from "../../../images/logoMain.png";
// import { motion } from "framer-motion";
import style from "./Login.module.scss";
import { useGlobalContext } from "../../../utils/contexts";
// import { ImSpinner10 } from "react-icons/im";
import CryptoJS from "crypto-js";
import { warningEnterAllDetailsToast } from "../../../utils/notifications";
import newlogo from "../../../assets/new_logo.svg";
import { IoCloseOutline } from "react-icons/io5";
import axios from "axios";
import { Bounce, toast } from "react-toastify";

const Login = (props) => {
  const [forgetForm, setForgetForm] = useState(false);
  // const emailRef = useRef();
  // const [isPopupOpen, setPopupOpen] = useState(false);
  const isPopupOpen = useState(false);
  const [message, setMessage] = useState("");
  //handler for closing the popup
  const handleCloseLoginForm = (e) => {
    if (e.target.classList.contains("loginSection")) {
      props.setActive(false);
    }
  };

  //states for storing user input for registration
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirm_Password] = useState("");

  // const [, setOnLoading] = useState(false);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [otp, setOtp] = useState("");
  const [verificationStatus, setVerificationStatus] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [verifyOtp, setverifyOtp] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const handleSendOTP = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/register/send-otp`,

        {
          phoneNumber,
          name,
        }
      );
      // if (response.status === 400) {
      //   toast.success("Account already exist");
      // }
      if (response.data.success) {
        setOtpSent(true);
      }

      // console.log(response);
    } catch (error) {
      // console.log("ye vala aaa kya", error.response.status);
      if (error.response.status === 400) {
        toast.error("Account already exist");
      } else {
        console.error("Error sending OTP:", error);
      }
      // setMessage("Error sending OTP");
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handleSendOTPForgot = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/reset/send-otp`,
        {
          phoneNumber,
        }
      );

      if (response.data.success) {
        setOtpSent(true);
        setMessage("OTP sent successfully");
      } else {
        setMessage(response.data.message);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setMessage("Error sending OTP");
    }
  };

  const handleVerifyOTP = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/verify-otp`,
        {
          phoneNumber,
          enteredOTP: otp,
        }
      );
      setVerificationStatus(response.data.message);
      if (response.data.success) {
        setverifyOtp(true);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };
  //getting global context
  const { setAuth } = useGlobalContext();

  const callRegistrationHandler = async (evt) => {
    evt.preventDefault();

    const data = { name, email, password, confirm_password };
    await registrationHandler(data, setAuth, props.setActive);
  };
  const callRegistrationHandlerwithPhone = async (evt) => {
    evt.preventDefault();

    const data = { name, phoneNumber, password, confirm_password };
    await registrationHandlerwithPhone(data, setAuth, props.setActive);
  };
  const callForgotHandlerwithPhone = async (evt) => {
    evt.preventDefault();

    const data = { phoneNumber, password, confirm_password };
    await forgotHandlerwithPhone(data, setAuth, props.setActive);
  };

  // const handleClick = async () => {
  //   setOnLoading(true);
  //   await passwordHandler(emailRef.current.value);
  //   setOnLoading(false);
  // };

  const handleButtonClick = () => {
    props.setActive(false);
  };
  const callLoginHandler = async (evt) => {
    evt.preventDefault();
    if (!email || !password) {
      warningEnterAllDetailsToast();
      return;
    } else {
      const hashedPassword = CryptoJS.AES.encrypt(
        password,
        `${process.env.REACT_APP_FRONTEND_PASS_HASHED_SECRET}`
      ).toString();

      const data = { email, password: hashedPassword };
      await loginHandler(data, setAuth, props.setActive);
    }
  };
  const callLoginHandlerwithPhone = async (evt) => {
    evt.preventDefault();
    if (!phoneNumber || !password) {
      warningEnterAllDetailsToast();
      return;
    } else {
      const hashedPassword = CryptoJS.AES.encrypt(
        password,
        `${process.env.REACT_APP_FRONTEND_PASS_HASHED_SECRET}`
      ).toString();

      const data = { phoneNumber, password: hashedPassword };
      await loginHandlerwithPhone(data, setAuth, props.setActive);
    }
  };
  const [otpTimer, setOtpTimer] = useState(300); // 300 seconds = 5 minutes
  const [, setOtpExpired] = useState(false);

  useEffect(() => {
    let timerInterval;

    if (otpTimer > 0) {
      timerInterval = setInterval(() => {
        setOtpTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setOtpExpired(true);
    }

    return () => clearInterval(timerInterval);
  }, [otpTimer]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  if (forgetForm) {
    return (
      <>
        <div
          onClick={handleCloseLoginForm}
          className={`z-50 font-poppins fixed top-0 left-0 w-full h-full flex items-center justify-center text-black ${
            isPopupOpen ? "blur-background" : "blur-background"
          }`}
        >
          <div className="flex flex-col md:mx-0 mx-4 gap-4 bg-white p-8 rounded shadow-2xl md:w-3/12 h-auto">
            <div className="flex justify-between">
              <div className="w-28">
                <img src={newlogo} alt="" />
              </div>
              <div>
                {" "}
                <button onClick={handleButtonClick} className="text-3xl">
                  {" "}
                  <IoCloseOutline />
                </button>
              </div>
            </div>
            <div className="mx-4">
              <form
                className="flex flex-col gap-4"
                onSubmit={(e) => e.preventDefault()}
              >
                <p className="text-[#114A82] text-[18px] lg:text-[17px] font-bold">
                  Enter Phone Number to get OTP
                </p>
                <input
                  type="text"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => {
                    // Add logic to prepend country code if not already present
                    const inputValue = e.target.value.trim();
                    const formattedPhoneNumber = inputValue.startsWith("+91")
                      ? inputValue
                      : `+91${inputValue}`;

                    setPhoneNumber(formattedPhoneNumber);
                  }}
                  className="font-poppins text-sm w-full p-2 border border-[#D8D9DA] rounded"
                />
                {!verifyOtp ? (
                  <div>
                    <input
                      type="text"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      placeholder="Enter OTP"
                      className="font-poppins text-sm w-full p-2 border border-[#D8D9DA] rounded"
                    />
                  </div>
                ) : (
                  ""
                )}

                {!otpSent ? (
                  <div>
                    <button
                      onClick={handleSendOTPForgot}
                      className="w-full bg-[#0169C2] py-2 font-poppins font-semibold text-white border rounded"
                    >
                      Send OTP
                    </button>
                    {message && <p className="text-center">{message}</p>}
                  </div>
                ) : (
                  ""
                )}

                {verifyOtp && (
                  <>
                    <div>
                      <input
                        value={password}
                        type="password"
                        required
                        placeholder="Password"
                        onChange={(evt) => setPassword(evt.target.value.trim())}
                        className="font-poppins text-sm w-full p-2 border border-[#D8D9DA] rounded"
                      />
                    </div>
                    <div>
                      <input
                        value={confirm_password}
                        type="password"
                        placeholder="Re-enter your password"
                        onChange={(evt) =>
                          setConfirm_Password(evt.target.value.trim())
                        }
                        className="font-poppins text-sm w-full p-2 border border-[#D8D9DA] rounded"
                      />
                    </div>
                    <button
                      className="w-full bg-[#0169C2] py-2 font-poppins font-semibold text-white border rounded"
                      onClick={callForgotHandlerwithPhone}
                    >
                      Create Password
                    </button>
                  </>
                )}
                {otpSent && !verifyOtp ? (
                  <p className="text-xs text-[#0096FF] font-normal flex justify-end md:py-4">
                    {formatTime(otpTimer)}
                  </p>
                ) : (
                  ""
                )}

                {otpSent && !verifyOtp ? (
                  <button
                    className="w-full bg-[#0169C2] py-2 font-poppins font-semibold text-white border rounded"
                    onClick={(e) => {
                      e.preventDefault();
                      handleVerifyOTP();
                    }}
                  >
                    Verify OTP
                  </button>
                ) : (
                  ""
                )}

                <p className="text-center text-[#198754]">
                  {verificationStatus}
                </p>
              </form>
            </div>{" "}
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div
        onClick={handleCloseLoginForm}
        className={`z-50 font-poppins fixed top-0 left-0 w-full h-full flex items-center justify-center text-black ${
          isPopupOpen ? "blur-background" : "blur-background"
        }`}
      >
        <div className="flex flex-col md:mx-0 mx-4 gap-4 bg-white p-8 rounded shadow-2xl md:w-3/12 h-auto">
          <div className="flex justify-between">
            <div className="w-28">
              <img src={newlogo} alt="" />
            </div>
            <div>
              {" "}
              <button onClick={handleButtonClick} className="text-3xl">
                {" "}
                <IoCloseOutline />
              </button>
            </div>
          </div>

          {/* otp verification for india country */}
          {timeZone === "Asia/Calcutta" ? (
            <>
              <div className="text-[#114A82] text-[18px] lg:text-[20px] font-bold">
                {props.registerWithOTP ? (
                  <p className="font-poppins md:ml-4">Join Us</p>
                ) : (
                  <p className="font-poppins md:ml-4">Login to continue</p>
                )}
              </div>

              {otpSent && !verifyOtp ? (
                <p className="font-poppins md:ml-4">
                  Enter Otp to verify Your Number
                </p>
              ) : (
                ""
              )}

              <div className="mx-4">
                <form
                  className="flex flex-col gap-1"
                  onSubmit={(e) => e.preventDefault()}
                >
                  {props.registerWithOTP && !verifyOtp ? (
                    <div>
                      <input
                        value={name}
                        type="text"
                        placeholder="name"
                        className="font-poppins text-sm w-full p-2 border border-[#D8D9DA] rounded"
                        onChange={(evt) => setName(evt.target.value)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {!verifyOtp ? (
                    <div>
                      <input
                        type="text"
                        placeholder="Phone Number"
                        value={phoneNumber}
                        onChange={(e) => {
                          // Add logic to prepend country code if not already present
                          const inputValue = e.target.value.trim();
                          const formattedPhoneNumber = inputValue.startsWith(
                            "+91"
                          )
                            ? inputValue
                            : `+91${inputValue}`;

                          setPhoneNumber(formattedPhoneNumber);
                        }}
                        className="md:mt-2 font-poppins text-sm w-full p-2 border border-[#D8D9DA] rounded"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {props.registerWithOTP ? (
                    ""
                  ) : (
                    <input
                      value={password}
                      type="password"
                      required
                      placeholder="Password"
                      onChange={(evt) => setPassword(evt.target.value.trim())}
                      className="md:mt-3 font-poppins text-sm w-full p-2 border border-[#D8D9DA] rounded"
                    />
                  )}

                  <div>
                    {props.registerWithOTP ? (
                      ""
                    ) : (
                      <p
                        className="text-xs text-[#0096FF] font-normal flex justify-end md:mt-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => setForgetForm(true)}
                      >
                        Forgot Password ?
                      </p>
                    )}
                  </div>

                  {props.registerWithOTP ? (
                    ""
                  ) : (
                    <button
                      className="w-full bg-[#0169C2] py-2 font-poppins font-semibold text-white border rounded md:mt-2"
                      onClick={callLoginHandlerwithPhone}
                    >
                      Log In
                    </button>
                  )}
                  {props.registerWithOTP && otpSent && !verifyOtp ? (
                    <div>
                      <input
                        type="text"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        placeholder="Enter OTP"
                        className="md:mt-2  font-poppins text-sm w-full p-2 border border-[#D8D9DA] rounded"
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {props.registerWithOTP && !otpSent ? (
                    <div>
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="whatsappPermission"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                        />
                        <label
                          htmlFor="whatsappPermission"
                          className="ml-2 block text-sm text-gray-900"
                        >
                          I give permission to send messages on WhatsApp
                        </label>
                      </div>
                      <button
                        onClick={handleSendOTP}
                        className="md:mt-4 w-full bg-[#0169C2] py-2 font-poppins font-semibold text-white border rounded"
                      >
                        Send OTP
                      </button>
                    </div>
                  ) : (
                    ""
                  )}

                  {otpSent && !verifyOtp ? (
                    <p className="text-xs text-[#0096FF] font-normal flex justify-end md:py-4">
                      {formatTime(otpTimer)}
                    </p>
                  ) : (
                    ""
                  )}

                  {props.registerWithOTP && otpSent && !verifyOtp ? (
                    <button
                      className="md:mt-4 w-full bg-[#0169C2] py-2 font-poppins font-semibold text-white border rounded"
                      onClick={(e) => {
                        e.preventDefault();
                        handleVerifyOTP();
                      }}
                    >
                      Verify OTP
                    </button>
                  ) : (
                    ""
                  )}

                  <p className="text-center text-[#198754]">
                    {verificationStatus}
                  </p>
                </form>

                {props.registerWithOTP && verifyOtp && (
                  <>
                    <p className="font-poppins md:ml-7">Enter Your Password</p>
                    <div>
                      <input
                        value={password}
                        type="password"
                        required
                        placeholder="Password"
                        onChange={(evt) => setPassword(evt.target.value.trim())}
                        className="md:mt-2 font-poppins text-sm w-full p-2 border border-[#D8D9DA] rounded"
                      />
                    </div>
                    <div>
                      <input
                        value={confirm_password}
                        type="password"
                        placeholder="Re-enter your password"
                        onChange={(evt) =>
                          setConfirm_Password(evt.target.value.trim())
                        }
                        className="md:mt-2 md:mb-2 font-poppins text-sm w-full p-2 border border-[#D8D9DA] rounded"
                      />
                    </div>
                    <button
                      className="w-full bg-[#0169C2] py-2 font-poppins font-semibold text-white border rounded"
                      onClick={callRegistrationHandlerwithPhone}
                    >
                      Create
                    </button>
                  </>
                )}

                {props.registerWithOTP && !otpSent ? (
                  <div className="flex justify-center items-center gap-1 md:mt-4">
                    <hr class="w-16 h-[1.8px]   bg-[#00000080] border-0 rounded" />
                    <p className="text-center text-sm ">or Sign up with</p>

                    <hr class="w-16 h-[1.8px]   bg-[#00000080] border-0 rounded" />
                  </div>
                ) : (
                  <div className="flex justify-center items-center gap-1 md:mt-4">
                    <hr class="w-16 h-[1.8px]   bg-[#00000080] border-0 rounded" />
                    <p className="text-center text-sm ">or Sign in with</p>

                    <hr class="w-16 h-[1.8px]   bg-[#00000080] border-0 rounded" />
                  </div>
                )}
              </div>
              {!otpSent ? (
                <div className="flex items-center justify-center">
                  <div className="px-4 py-2 border flex items-center gap-2 border-slate-200 dark:border-slate-700 rounded-lg text-slate-700 cursor-pointer">
                    <GoogleOAuthProvider
                      clientId={process.env.REACT_APP_GOOGLE_CLIENTID}
                    >
                      <GoogleLogin
                        setActive={props.setActive}
                        setAuth={setAuth}
                        isRegister={props.registerForm}
                        registerWithOTP={props.registerWithOTP}
                        buttonText={
                          props.registerWithOTP
                            ? "Register with Google"
                            : "Login with Google"
                        }
                      />
                    </GoogleOAuthProvider>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className=" text-black text-center">
                {props.registerWithOTP && !otpSent ? (
                  <button
                    className="text-sm"
                    onClick={() => props.setRegisterWithOTP(false)}
                  >
                    Already have an account ?{" "}
                    <span className="text-[#298BD0] font-semibold">Log In</span>
                  </button>
                ) : (
                  <button
                    className="text-sm"
                    onClick={() => props.setRegisterWithOTP(true)}
                  >
                    Don't have an account ?{" "}
                    <span className="text-[#298BD0] font-semibold">
                      Sign Up
                    </span>
                  </button>
                )}
              </div>
            </>
          ) : (
            // login with email for foregin country
            <>
              <div className="text-[#114A82] text-[18px] lg:text-[20px] font-bold">
                {props.registerForm ? (
                  <>
                    <p className="font-poppins text-center">
                      Sign Up for an account
                    </p>
                  </>
                ) : (
                  <>
                    <p className="font-poppins text-center">
                      Sign In to your account
                    </p>
                  </>
                )}
              </div>

              {/* start of the form  */}
              <div className="mx-4">
                <form className="flex flex-col gap-4 ">
                  {props.registerForm ? (
                    <div>
                      <input
                        value={name}
                        type="text"
                        placeholder="name"
                        className="font-poppins text-sm w-full p-2 border border-[#D8D9DA] rounded"
                        onChange={(evt) => setName(evt.target.value)}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {/* //note: login is receiving registerForm state for setting register form inputs*/}
                  <div className={style.email}>
                    <input
                      value={email}
                      required
                      type="email"
                      placeholder="email"
                      onChange={(evt) => setEmail(evt.target.value.trim())}
                      className="font-poppins text-sm w-full p-2 border border-[#D8D9DA] rounded"
                    />
                  </div>

                  <div>
                    <input
                      value={password}
                      type="password"
                      required
                      placeholder="Password"
                      onChange={(evt) => setPassword(evt.target.value.trim())}
                      className="font-poppins text-sm w-full p-2 border border-[#D8D9DA] rounded"
                    />
                  </div>
                  {/* registration form input  */}

                  {props.registerForm ? (
                    <div className={style.email}>
                      <input
                        value={confirm_password}
                        type="password"
                        placeholder="Re-enter your password"
                        onChange={(evt) =>
                          setConfirm_Password(evt.target.value.trim())
                        }
                        className="font-poppins text-sm w-full p-2 border border-[#D8D9DA] rounded"
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  <div>
                    {props.registerForm ? (
                      ""
                    ) : (
                      <p
                        className="text-xs text-[#0096FF] font-normal flex justify-end md:py-4"
                        style={{ cursor: "pointer" }}
                        onClick={() => setForgetForm(true)}
                      >
                        Forgot Password ?
                      </p>
                    )}
                  </div>
                  {/* if register form state is true submit button will act as a register buttton  else it will act as login button   */}

                  {props.registerForm ? (
                    <button
                      className="w-full bg-[#0169C2] py-2 font-poppins font-semibold text-white border rounded"
                      type="submit"
                      onClick={callRegistrationHandler}
                    >
                      Register
                    </button>
                  ) : (
                    <button
                      className="w-full bg-[#0169C2] py-2 font-poppins font-semibold text-white border rounded"
                      type="submit"
                      onClick={callLoginHandler}
                    >
                      Login
                    </button>
                  )}
                </form>
              </div>
              {props.registerForm ? (
                <div className="flex justify-center items-center gap-1">
                  <hr class="w-16 h-[1.8px]   bg-[#00000080] border-0 rounded" />
                  <p className="text-center text-sm ">or Sign in with</p>

                  <hr class="w-16 h-[1.8px]   bg-[#00000080] border-0 rounded" />
                </div>
              ) : (
                <div className="flex justify-center items-center gap-1">
                  <hr class="w-16 h-[1.8px]   bg-[#00000080] border-0 rounded" />
                  <p className="text-center text-sm ">or Sign in with</p>

                  <hr class="w-16 h-[1.8px]   bg-[#00000080] border-0 rounded" />
                </div>
              )}
              <div className="flex items-center justify-center">
                <div className="px-4 py-2 border flex items-center gap-2 border-slate-200 dark:border-slate-700 rounded-lg text-slate-700 cursor-pointer">
                  <GoogleOAuthProvider
                    clientId={process.env.REACT_APP_GOOGLE_CLIENTID}
                  >
                    <GoogleLogin
                      setActive={props.setActive}
                      setAuth={setAuth}
                      isRegister={props.registerForm}
                      buttonText={
                        props.registerForm
                          ? "Register with Google"
                          : "Login with Google"
                      }
                    />
                  </GoogleOAuthProvider>
                </div>
              </div>
              {props.registerForm ? (
                <button
                  className="text-sm"
                  onClick={() => props.setRegisterForm(false)}
                >
                  Already have an account ?{" "}
                  <span className="text-[#298BD0] font-semibold">Log In</span>
                </button>
              ) : (
                <button
                  className="text-sm"
                  onClick={() => props.setRegisterForm(true)}
                >
                  Don't have an account ?{" "}
                  <span className="text-[#298BD0] font-semibold">Sign Up</span>
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default Login;
