import { Navigate } from "react-router-dom";
import { useGlobalContext } from "../../utils/contexts";
import LoadPage from "../LoadPage/LoadPage";

function RequiredCoach({ children }) {
  // get user
  const { coachData, coachLoading } = useGlobalContext();

  if (coachLoading) {
    return <LoadPage />;
  }
  if (!coachData?._id) {
    return <Navigate to="/" />;
  } else {
    return children;
  }
}

export default RequiredCoach;
