import { Navigate } from "react-router-dom";
import { useGlobalContext } from "../../utils/contexts";

function RequiredNormalUser({ children }) {
  // get user
  const { auth, coachData } = useGlobalContext();
  if (coachData?._id || !auth?._id) {
    return <Navigate to="/" />;
  } else {
    return children;
  }
}

export default RequiredNormalUser;